import React from 'react';

import { Container } from '../../components/home/v2/styledComponents';
import Layout from '../../layouts/index';
import MenuBar from '../../components/home/MenuBar';
import Header from '../../components/partner/Header';
import OurPartnersBlock from '../../components/partner/OurPartnersBlock';
import Banner from '../../components/partner/Banner';
import IntegrationBlock from '../../components/partner/IntegrationBlock';
import EcoSystemBlock from '../../components/partner/EcoSystemBlock';
import PackOrderBlock from '../../components/partner/PackOrderBlock';
import BecomePartnerModal from '../../components/partner/BecomePartnerModal';
import Footer from '../../components/home/v3/Footer';

class WhyBecomePartner extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal() {
    if (typeof window !== 'undefined') window.scrollTo(0, 0);
    this.setState({ showModal: true });
  }

  renderBecomePartnerModal = () => (
    <BecomePartnerModal closeModal={() => this.closeModal()} />
  );

  render() {
    if (this.state.showModal) return this.renderBecomePartnerModal();

    return (
      <Layout routeSlug="WhyBecomePartner">
        <Container style={{ overflow: 'hiden' }}>
          <MenuBar />
          <Header />
          <OurPartnersBlock />
          <Banner />
          <IntegrationBlock openModal={this.openModal} />
          <EcoSystemBlock />
          <PackOrderBlock openModal={this.openModal} />
          <Footer />
        </Container>
      </Layout>
    );
  }
}

export default WhyBecomePartner;
