import React from 'react';
import styled from 'styled-components';

import Dots from '../home/v3/Dots';
import PartnersPager from '../../components/partner/PartnersPager';
import { Title2, mobileThresholdPixels } from '../home/v3/styledComponents';
import { margins } from '../home/v2/styledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px ${margins.s};
    margin-top: ${margins.m};
  }
`;

const OurPartnersBlock = () => (
  <Container>
    <Title2>Ils nous confient leurs retouches</Title2>
    <Dots noMargin />
    <PartnersPager />
  </Container>
);

export default OurPartnersBlock;
