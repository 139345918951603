import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../../styledComponents';
import { colors as colorsV3 } from '../../home/v3/styledComponents';

import { alterationsOffers } from '../becomePartnerModalData';

const Label = styled.label`
  font-size: 18px;
  line-height: 154.69%;
  color: ${colors.navy};
  display: block;
  margin-bottom: 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

const CustomCheckboxWrapper = styled.div`
  display: flex;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const CustomCheckbox = styled.div`
  width: 243px;
  height: 37px;
  border: 1px solid ${colorsV3.blueGrey};
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: ${colors.navy};
  padding: 0px 15px;
  cursor: pointer;
  margin: 0px 13px;
  

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  &.isActive {
    background-color: ${colors.navy};
    color: ${colors.white};
    border: 0px;
    box-shadow: 0px 1px 6px ${colorsV3.blueGrey};
  }

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    margin: 5px 0px;
    font-size: 14px;

    &:first-child {
      margin-top: 0px;
    }
  
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const CustomCheckboxWrapperInput = styled.div`
  display: flex;

  label {
    margin-right: 10px;
    cursor: pointer;

    &:last-child {
      margin-left: 10px;
      margin-right: 0px;
    }
  }
`;

const CustomCheckboxWithInputText = styled.input`
  border: none;
  width: 125px;
  border-bottom: 1px solid ${({ isActive }) => isActive ? colors.white : colors.navy};
  outline: none;
  font-size: 12px;
  line-height: 175.78%;
  color: ${colors.white};
  padding: 2px 0px 0px;
  box-sizing: border-box;
  background-color: ${({ isActive }) => isActive ? colors.navy : colors.white};

  &::placeholder {
    font-size: 12px;
    line-height: 175.78%;
    color: ${colors.navy};
    opacity: 0.25;
  }
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  line-height: 175.78%;
  color: ${colorsV3.red};
  margin: 8px 0px 0px;
`;

const CustomerAdjustmentOffers = ({ state, selectCustomerAdjustmentOffer, changeAlterationsOfferAmount }) => (
  <>
    <Label>Offre retouche pour mes clients*</Label>
    <CustomCheckboxWrapper>
      {alterationsOffers.map(alterationsOffer => (
        <CustomCheckbox
          key={alterationsOffer.slug}
          className={alterationsOffer.slug === state.alterationsOffer?.slug ? 'isActive' : ''}
          onClick={() => selectCustomerAdjustmentOffer(alterationsOffer)}
        >
          {alterationsOffer.custom === 'withInputText' ? (
            <CustomCheckboxWrapperInput>
              <label htmlFor={alterationsOffer.slug}>{alterationsOffer.label}</label>
              <CustomCheckboxWithInputText
                id={alterationsOffer.slug}
                type="number"
                name={alterationsOffer.slug}
                placeholder="Saisir le montant"
                isActive={alterationsOffer.slug === state.alterationsOffer?.slug}
                value={state.alterationsOffer?.slug === 'amount' ? state.alterationsOfferAmount : ''}
                onChange={changeAlterationsOfferAmount}
              />
              <label htmlFor={alterationsOffer.slug}>€</label>
            </CustomCheckboxWrapperInput>
          ) : (
            <span>{alterationsOffer.label}</span>
          )}
        </CustomCheckbox>
      ))}
    </CustomCheckboxWrapper>
    {!state.isAlterationsOfferValid && state.alterationsOffer === '' &&
      <ErrorMessage>Veuillez sélectionner un type d’offre</ErrorMessage>
    }
  </>
);

CustomerAdjustmentOffers.propTypes = {
  state: PropTypes.shape({}).isRequired,
  selectCustomerAdjustmentOffer: PropTypes.func.isRequired,
  changeAlterationsOfferAmount: PropTypes.func.isRequired,
};

CustomerAdjustmentOffers.defaultProps = {
  selectCustomerAdjustmentOffer: () => {},
  changeAlterationsOfferAmount: () => {},
};

export default CustomerAdjustmentOffers;
