import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { margins } from '../home/v2/styledComponents';
import { colors, fontSizes, mobileThresholdPixels, Button } from '../home/v3/styledComponents';

import image from '../../assets/partners/v2/packOpening.icon.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.navy};
  background-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px ${margins.s};
  }
`;

const Img = styled.img`
  margin: ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.l} 0px ${margins.s};
  }
`;

const Span1 = styled.span`
  font-size: 22px;
  text-align: center;
  font-weight: 500;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const Span2 = styled.span`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  margin: 7px 0px ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: ${margins.m};
`;

const StyledButton = styled(Button)`
  min-width: 180px;

  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 14px;
    padding: ${margins.xs};
  }
`;

const PackOrderBlock = ({ openModal }) => (
  <Container>
    <Img src={image} alt={'Park Tilli ouvert'} />
    <Span1>Prêts à rééduquer nos clients ensemble&nbsp;?</Span1>
    <ButtonContainer>
      <StyledButton navy onClick={openModal}>Recevoir le pack <br /> Tilli</StyledButton>
    </ButtonContainer>
    <Span2>ou contactez-nous à l’adresse bdelabouchere@tilli.fr</Span2>
  </Container>
);

PackOrderBlock.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default PackOrderBlock;
