import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, margins, mobileThresholdPixels } from '../styledComponents';

import TopForm from './BecomePatnerModal/TopForm';
import PartnershipsList from './BecomePatnerModal/PartnershipsList';
import AlterationsTypesSelector from './BecomePatnerModal/AlterationsTypesSelector';
import ServicesList from './BecomePatnerModal/ServicesList';
import CustomerAdjustmentOffers from './BecomePatnerModal/CustomerAdjustmentOffers';
import ContactDetailsForm from './BecomePatnerModal/ContactDetailsForm';

import { partnershipTypes, serviceTypes } from './becomePartnerModalData';

import callApi from '../../services/api';

const Background = styled.div`
  background-color: ${colors.lightGrey};
  min-height: 100vh;
  width: 100vw;
  z-index: 4;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  margin: ${margins.x2l} 0px ${margins.xl};
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 872px;
  max-width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.xl} ${margins.s};
  }
`;

const Container = styled.div`
  background-color: ${colors.white};
  padding: 66px 45px;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 23px;
    padding: 30px 15px 45px 15px;
    width: calc(100% - 46px);
  }
`;

const Wrapper = styled.div`
  margin-bottom: 66px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 45px;
  }
`;

const Subtitle = styled.h2`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-weight: normal;
  font-size: 26px;
  line-height: 154.69%;
  margin: 0px 0px 30px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    margin: 0px 0px 12px;
  }
`;

const SubmitButton = styled.button`
  background: ${colors.white};
  box-shadow: 0px 5px 27px rgba(0, 0, 0, 0.05);
  outline: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 154.69%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  width: 183px;
  height: 57px;
  border: 1.5px solid ${colors.navy};
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.navy};
  transition: all 0.3s ease;
  margin: auto;

  &:hover {
    color: ${colors.white};
    background: ${colors.navy};
  }

  @media (max-width: ${mobileThresholdPixels}) {
    width: 126px;
    height: 44px;
    font-size: 14px;
  }
`;

class BecomePartnerModal extends Component {
  state = {
    partnershipType: partnershipTypes[0],
    alterationsTypes: [],
    alterationsOffer: '',
    alterationsOfferAmount: '',
    serviceType: serviceTypes[0],
    brandName: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactComment: '',
    isOpenPartnershipsList: false,
    isOpenServicesList: false,
    isContactFormValid: true,
    isAlterationsOfferValid: true,
  };

  changeAlterationsOfferAmount = (event) => {
    this.setState({ alterationsOfferAmount: event.target.value });
  }

  handleInputsChange = (event) => {
    const target = event.target;

    this.setState({
      [target.name]: target.value,
    });
  }

  hasClickedInsideModale = (event) => {
    const flyoutElement = document.getElementById('modal');
    let targetElement = event.target;

    do {
      if (targetElement === flyoutElement) {
        return true;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);

    return false;
  };

  selectAlterationsType = (slug) => {
    let alterationsTypes = [...this.state.alterationsTypes];

    if (alterationsTypes.includes(slug)) {
      alterationsTypes = alterationsTypes.filter(alterationsType => alterationsType !== slug);
    } else {
      alterationsTypes.push(slug);
    }

    this.setState({ alterationsTypes });
  }

  selectCustomerAdjustmentOffer = (alterationsOffer) => {
    this.setState({ alterationsOffer });
  }

  selectPartnership = (partnershipType) => {
    this.setState(prevState => ({
      partnershipType,
      serviceType: serviceTypes[0],
      alterationsType: [],
      alterationsOffer: partnershipType.slug === 'other' ? '' : prevState.alterationsOffer,
    }), () => {
      this.toggleSelect('isOpenPartnershipsList');
    });
  }

  selectService = (serviceType) => {
    this.setState({ serviceType }, () => {
      this.toggleSelect('isOpenServicesList');
    });
  }

  checkData() {
    const {
      partnershipType, alterationsOffer,
      brandName, contactName, contactEmail, contactPhone,
    } = this.state;

    let hasError = false;
    if (brandName === '' || contactName === '' || contactEmail === '' || contactPhone === '') {
      this.setState({ isContactFormValid: false });
      hasError = true;
    }
    if (partnershipType.slug !== 'other' && alterationsOffer === '') {
      this.setState({ isAlterationsOfferValid: false });
      hasError = true;
    }
    return !hasError;
  }

  submitForm = async () => {
    const {
      partnershipType, alterationsTypes, serviceType, alterationsOffer, alterationsOfferAmount,
      brandName, contactName, contactEmail, contactPhone, contactComment,
    } = this.state;
    const { closeModal } = this.props;

    if (!this.checkData()) return false;

    const partnerDemand = {
      partnershipType: partnershipType.slug,
      alterationsType: alterationsTypes.join(', '),
      serviceType: partnershipType.slug === 'popup' ? serviceType.slug : '',
      alterationsOffer: partnershipType.slug === 'other' ? '' : alterationsOffer.slug,
      alterationsOfferAmount,
      brandName,
      contactName,
      contactEmail,
      contactPhone,
      contactComment,
    };

    return callApi('partnerDemands', 'post', { partnerDemand }).then(() => closeModal());
  }

  toggleSelect = (event) => {
    const name = !event.target ? event : event.target.dataset.name;

    this.setState(prevState => ({
      [name]: !prevState[name],
    }));
  }

  render() {
    const { closeModal } = this.props;
    const { partnershipType } = this.state;

    return (
      <Background onClick={e => !this.hasClickedInsideModale(e) && closeModal()}>
        <Modal id="modal">
          <Container>
            <TopForm />
            <Wrapper>
              <Subtitle>Votre demande</Subtitle>
              <PartnershipsList
                state={this.state}
                toggleSelect={this.toggleSelect}
                selectPartnership={this.selectPartnership}
              />
            </Wrapper>
            {partnershipType.slug === 'plugin' &&
              <Wrapper>
                <AlterationsTypesSelector state={this.state} selectAlterationsType={this.selectAlterationsType} />
              </Wrapper>
            }
            {partnershipType.slug === 'popupStore' &&
              <Wrapper>
                <ServicesList
                  state={this.state}
                  toggleSelect={this.toggleSelect}
                  selectService={this.selectService}
                />
              </Wrapper>
            }
            {partnershipType.slug !== 'other' &&
              <Wrapper>
                <CustomerAdjustmentOffers
                  state={this.state}
                  selectCustomerAdjustmentOffer={this.selectCustomerAdjustmentOffer}
                  changeAlterationsOfferAmount={this.changeAlterationsOfferAmount}
                />
              </Wrapper>
            }
            <Wrapper>
              <ContactDetailsForm state={this.state} handleInputsChange={this.handleInputsChange} />
            </Wrapper>
            <SubmitButton onClick={this.submitForm}>Envoyer</SubmitButton>
          </Container>
        </Modal>
      </Background>
    );
  }
}

BecomePartnerModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

BecomePartnerModal.defaultProps = {
  closeModal: () => {},
};

export default BecomePartnerModal;
