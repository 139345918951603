import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../../styledComponents';
import { colors as colorsV3 } from '../../home/v3/styledComponents';

import { alterationsTypes } from '../becomePartnerModalData';

const Label = styled.label`
  font-size: 18px;
  line-height: 154.69%;
  color: ${colors.navy};
  display: block;
  margin-bottom: 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

const CustomCheckboxWrapper = styled.div`
  display: flex;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const CustomCheckbox = styled.div`
  width: 243px;
  height: 37px;
  border: 1px solid ${colorsV3.blueGrey};
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: ${colors.navy};
  padding: 0px 15px;
  cursor: pointer;
  margin: 0px 13px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  &.isActive {
    background-color: ${colors.navy};
    color: ${colors.white};
    border: 0px;
    box-shadow: 0px 1px 6px ${colorsV3.blueGrey};
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    width: 100%;
    margin: 5px 0px;
  }
`;

const AlterationsTypesSelector = ({ state, selectAlterationsType }) => (
  <>
    <Label>Nature des retouches à intégrer</Label>
    <CustomCheckboxWrapper>
      {alterationsTypes.map(({ slug, label }) => (
        <CustomCheckbox
          key={slug}
          className={state.alterationsTypes.includes(slug) ? 'isActive' : ''}
          onClick={() => selectAlterationsType(slug)}
        >
          {label}
        </CustomCheckbox>
      ))}
    </CustomCheckboxWrapper>
  </>
);

AlterationsTypesSelector.propTypes = {
  state: PropTypes.shape({}).isRequired,
  selectAlterationsType: PropTypes.func.isRequired,
};

AlterationsTypesSelector.defaultProps = {
  selectAlterationsType: () => {},
};

export default AlterationsTypesSelector;
