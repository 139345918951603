export const partnershipTypes = [
  {
    label: 'Avoir un plug-in e-commerce et/ou une plateforme retail',
    slug: 'plugin',
  },
  {
    label: 'Un atelier pop-up',
    slug: 'popupStore',
  },
  {
    label: 'J’ai une autre idée ;)',
    slug: 'other',
  },
];

export const alterationsTypes = [
  {
    label: 'Vêtements',
    slug: 'clothes',
    isActive: false,
  },
  {
    label: 'Décoration',
    slug: 'decoration',
    isActive: false,
  },
];

export const alterationsOffers = [
  {
    label: 'Offrir 100% des retouches',
    slug: '100%',
  },
  {
    label: 'Offrir :',
    slug: 'amount',
    custom: 'withInputText',
  },
  {
    label: 'Juste proposer le service',
    slug: 'no',
  },
];

export const serviceTypes = [
  {
    label: 'La retouche',
    slug: 'adjustment',
  },
  {
    label: 'L’upcycling',
    slug: 'upcycling',
  },
  {
    label: 'La broderie',
    slug: 'embroidery',
  },
  {
    label: 'Création d’accessoires',
    slug: 'creation',
  },
];

export const contactFormInputs = [
  {
    label: 'Nom de la marque*',
    name: 'brandName',
    type: 'text',
    errorMessage: 'Veuillez saisir le nom de la marque',
  },
  {
    label: 'Nom et prénom du contact*',
    name: 'contactName',
    type: 'text',
    errorMessage: 'Veuillez saisir le nom et prénom du contact',
  },
  {
    label: 'Adresse mail*',
    name: 'contactEmail',
    type: 'email',
    errorMessage: 'Veuillez renseigner l’adresse mail',
  },
  {
    label: 'Numéro de téléphone*',
    name: 'contactPhone',
    type: 'text',
    errorMessage: 'Veuillez renseigner le numéro de téléphone',
  },
];
