import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import Dots from '../home/v3/Dots';
import { margins } from '../home/v2/styledComponents';
import { mobileThresholdPixels, colors, fontSizes, Title2, Button } from '../home/v3/styledComponents';

import image1 from '../../assets/partners/v2/block.image.1.jpg';
import imageMobile1 from '../../assets/partners/v2/block.image.mobile.1.jpg';
import image2 from '../../assets/partners/v2/block.image.2.jpg';
import imageMobile2 from '../../assets/partners/v2/block.image.mobile.2.jpg';

const Container = styled.div`
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${margins.xl} 0px 0px;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.l} 0px 0px;
    padding: 0px ${margins.s};
  }
`;

const SubContainer = styled.div``;

const BlockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.backgroundGrey && `
    background-color: ${colors.lightGrey};
    flex-direction: row-reverse;
  `}
  width: 100vw;
  margin-bottom: ${margins.xl};
  padding: ${margins.m} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column-reverse;
    margin-bottom: ${margins.l};
  }
`;

const TextContainer = styled.div`
  max-width: 500px;
  margin: ${props => props.marginLeft ?
    `0px 0px 0px ${margins.xl}` :
    `0px ${margins.l} 0px 0px`
};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px ${margins.m};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Libre Baskerville;
`;

const Title3 = styled.h3`
  font-size: 32px;
  font-weight: 500;
  padding-left: ${margins.s};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 22px;
    padding-left: 10px;
  }
`;

const NumberSpan = styled.span`
  font-size: ${fontSizes.x3l};
  font-weight: bold;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.x2l};
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: 56px 0px ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px;
  }
`;

const ImgContainer = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${margins.m};
  }
`;

const Img = styled.img``;
const NoBreakingSpan = styled.span`white-space: nowrap;`;

const StyledButton = styled(Button)`
  min-width: 180px;

  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 14px;
    padding: ${margins.xs};
  }
`;

const Span = styled.span`
  font-size: ${fontSizes.m};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const Ul = styled.ul`
  list-style-type: disc;
  padding-inline-start: 64px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding-inline-start: 34px;
  }
`;

const Li = styled.li`
  font-size: ${fontSizes.m};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const IntegrationBlock = ({ openModal, context: { isMobile } }) => {
  const Blocks = [
    {
      title: 'Plug-in parcours client',
      image: isMobile ? imageMobile1 : image1,
      imageMobile: imageMobile1,
      imageAlt: 'screenshot plug-in parcours client',
      content: <Ul>
        <Li>Une ligne de code à copier-coller.</Li>
        <Li>
          Proposer un service de retouche comme alternative aux retours colis.
          Installer le plug-in en moins de 5min sur la ou les pages de votre choix.
        </Li>
        <Li>Baisser vos retours colis et augmenter votre qualité de service&nbsp;!</Li>
      </Ul>,
    },
    {
      title: 'Communication',
      image: isMobile ? imageMobile2 : image2,
      imageMobile: imageMobile2,
      imageAlt: 'pantalon retouché par Tilli',
      content: <Span>
        Mettez toutes le chances de votre côté en annonçant votre nouveau service sur vos canaux de communication.
        Le message apposé à un moment stratégique peut soit réduire le retour colis soit créer la vente.<br /><br />
        On en discute ?
        <ButtonContainer>
          <StyledButton navy onClick={openModal}>Recevoir le pack <br /> Tilli</StyledButton>
        </ButtonContainer>
      </Span>,
    },
  ];

  return (
    <Container>
      <Title2>Comment j’intègre Tilli à mon site <NoBreakingSpan>e-commerce ?</NoBreakingSpan></Title2>
      <Dots />
      <SubContainer>
        {Blocks.map((block, index) =>
          <BlockContainer key={block.title} backgroundGrey={index % 2 !== 0}>
            <TextContainer marginLeft={index % 2 !== 0}>
              <TitleContainer>
                <NumberSpan>{index + 1}.</NumberSpan>
                <Title3>{block.title}</Title3>
              </TitleContainer>
              {block.content}
            </TextContainer>
            <ImgContainer>
              <Img src={block.image} alt={block.imageAlt} />
            </ImgContainer>
          </BlockContainer>,
        )}
      </SubContainer>
    </Container>
  );
};

IntegrationBlock.propTypes = {
  openModal: PropTypes.func.isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(IntegrationBlock);
