import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../../styledComponents';
import { colors as colorsV3 } from '../../home/v3/styledComponents';

import chevron from '../../../assets/becomePartnerModal/chevron.svg';

import { serviceTypes } from '../becomePartnerModalData';

const Label = styled.label`
  font-size: 18px;
  line-height: 154.69%;
  color: ${colors.navy};
  display: block;
  margin-bottom: 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const SelectWrapper = styled.div`
  width: 512px;
  max-width: 100%;
  position: relative;
`;

const SelectDefault = styled.div`
  border: 1px solid ${colorsV3.blueGrey};
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 37px;
  cursor: pointer;
  padding: 0px 35px 0px 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: ${colors.navy};
  background-image: url(${chevron});
  background-position: right 15px top 50%;
  background-repeat: no-repeat;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 1;
  }
`;

const SelectUl = styled.ul`
  position: absolute;
  display: none;
  border: 1px solid ${colorsV3.blueGrey};
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  margin: 3px 0px 0px;
  padding: 0px;
  list-style: none;
  background-color: ${colors.white};
  z-index: 1;

  &.isActive {
    display: block;
  }
`;

const SelectLi = styled.li`
  height: 37px;
  padding: 0px 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: ${colors.navy};
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${colors.lightGrey};
  }

  &.isActive {
    background-color: ${colors.navy};
    color: ${colors.white};
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 1;
  }
`;

const ServicesList = ({ state, toggleSelect, selectService }) => (
  <>
    <Label>Pour quel(s) service(s) ?</Label>
    <SelectWrapper>
      <SelectDefault data-name="isOpenServicesList" onClick={e => toggleSelect(e)}>
        {state.serviceType.label}
      </SelectDefault>
      <SelectUl className={state.isOpenServicesList ? 'isActive' : ''}>
        {serviceTypes.map((serviceType => (
          <SelectLi
            key={serviceType.slug}
            className={serviceType.slug === state.serviceType.slug ? 'isActive' : ''}
            onClick={() => selectService(serviceType)}
          >
            {serviceType.label}
          </SelectLi>
        )))}
      </SelectUl>
    </SelectWrapper>
  </>
);

ServicesList.propTypes = {
  state: PropTypes.shape({}).isRequired,
  toggleSelect: PropTypes.func.isRequired,
  selectService: PropTypes.func.isRequired,
};

ServicesList.defaultProps = {
  toggleSelect: () => {},
  selectService: () => {},
};

export default ServicesList;
