import React from 'react';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../../styledComponents';

import logo from '../../../assets/logos/tilli.navy.svg';

const Logo = styled.img`
  width: 300px;
  display: flex;
  margin: 0 auto 66px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 50px;
    width: 185px;
  }
`;

const Title = styled.h1`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  margin: 0 0 20px 0;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const Text = styled.p`
  color: ${colors.navy};
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin: 0 0 66px 0;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 154.69%;
    margin-bottom: 30px;
  }
`;

const TopForm = () => (
  <>
    <Logo src={logo} alt="TILLI" />
    <Title>Formulaire de contact</Title>
    <Text>
      Merci de renseigner les informations ci-dessous pour que notre équipe<br />
      puisse vous proposer une solution adaptée !
    </Text>
  </>
);

export default TopForm;
