import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../../styledComponents';
import { colors as colorsV3 } from '../../home/v3/styledComponents';

import { contactFormInputs } from '../becomePartnerModalData';

const Subtitle = styled.h2`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 154.69%;
  margin: 0px 0px 30px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    margin: 0px 0px 12px;
  }
`;

const FieldRow = styled.div`
  margin-bottom: 40px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const Label = styled.label`
  font-size: 18px;
  line-height: 154.69%;
  color: ${colors.navy};
  display: block;
  margin-bottom: 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const Input = styled.input`
  border: 1px solid ${colorsV3.blueGrey};
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 37px;
  padding: 0px 10px;
  font-size: 15px;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
  }
`;

const Textarea = styled.textarea`
  border: 1px solid ${colorsV3.blueGrey};
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 137px;
  padding: 10px;
  font-size: 15px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
  }
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${colorsV3.red};
  margin: 8px 0px 0px;
`;

const ContactDetailsForm = ({ state, handleInputsChange }) => (
  <>
    <Subtitle>Vos coordonnées</Subtitle>
    {contactFormInputs.map(({ name, label, type, errorMessage }) => (
      <FieldRow key={name}>
        <Label htmlFor={name}>{label}</Label>
        <Input
          id={name}
          type={type}
          name={name}
          onChange={handleInputsChange}
        />
        {!state.isContactFormValid && state[name] === '' &&
          <ErrorMessage>{errorMessage}</ErrorMessage>
        }
      </FieldRow>
    ))}
    <div>
      <Label htmlFor="contactComment">Commentaire</Label>
      <Textarea
        id="contactComment"
        name="contactComment"
        onChange={handleInputsChange}
      />
    </div>
  </>
);

ContactDetailsForm.propTypes = {
  state: PropTypes.shape({}).isRequired,
  handleInputsChange: PropTypes.func.isRequired,
};

ContactDetailsForm.defaultProps = {
  handleInputsChange: () => {},
};

export default ContactDetailsForm;
