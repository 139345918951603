import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import { margins } from '../home/v2/styledComponents';
import { mobileThresholdPixels, fontSizes, colors } from '../home/v3/styledComponents';

import purchase from '../../assets/partners/v2/purchase.icon.png';
import review from '../../assets/partners/v2/review.icon.png';
import timer from '../../assets/partners/v2/timer.icon.png';

const Span = styled.span`
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: left;
  }
`;

const Emph = styled.span`font-weight: bold`;

const list = [
  {
    image: purchase,
    imageAlt: 'Icon retour de colis',
    text: <Span><Emph>Réduction</Emph> de votre taux de <br /> retour colis</Span>,
    textMobile: <Span><Emph>Réduction</Emph> de votre taux de retour colis</Span>,
  },
  {
    image: review,
    imageAlt: 'Icon satisfaction client',
    text: <Span><Emph>Augmentation</Emph> de votre <br /> satisfaction client</Span>,
    textMobile: <Span><Emph>Augmentation</Emph> de votre satisfaction client</Span>,
  },
  {
    image: timer,
    imageAlt: 'Icon gain de temps',
    text: <Span><Emph>Intégration</Emph>  en une ligne de <br /> code</Span>,
    textMobile: <Span><Emph>Intégration</Emph>  en une ligne de code</Span>,
  },
];

const Container = styled.div`
  background-color: ${colors.navy};
  color: ${colors.white};
  font-size: ${fontSizes.m};
  padding: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: flex-start;
    padding: ${margins.m} ${margins.s};
  }
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: row;
    font-size: ${fontSizes.s};
  }
`;

const Img = styled.img`
  margin-bottom: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 20px ${margins.m};
    width: 59px;
  }
`;

const Banner = ({ context: { isMobile } }) => (
  <Container>
    <SubContainer>
      {list.map(element =>
        <ContentContainer key={element.imageAlt}>
          <Img src={element.image} alt={element.imageAlt} />
          {isMobile ? element.textMobile : element.text}
        </ContentContainer>,
      )}
    </SubContainer>
  </Container>
);

Banner.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(Banner);
